<template>
  <div class="col-12 col-lg-6 c g">
    <br><br>
    <h2 v-if="school_name" class="g col-12 text-center text-muted">
        {{ school_name }}
    </h2>
    <div class="card">
        <div class="card-body">
            <div class="form-group">
              <h5 for="">رقم هوية الطالب</h5>
              <input type="text"
                class="form-control form-control-lg" v-model="number" placeholder="اكتب رقم الهوية هنا...">
            </div>
            <div class="col-12 text-center c g">
                <button class="btn btn-primary" @click="getResults()">
                    <i class="fa fa-search"></i>
                    الاستعلام عن الشهادات
                </button>
            </div>
        </div>
        <div class="card-body">
            <img :src="require('@/assets/images/loading.svg')" v-if="loading" class="c" style="width: 100px">
            <div class="col-12 table-responsive" v-if="name">
                <div class="alert alert-success g text-dark text-center">
                    <h5>
                        {{ name }}
                    </h5>
                        عدد الشاهدات الموجودة: {{ results.length }}
                </div>
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            العنوان
                        </th>
                        <th>
                            عرض الشهادة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="result in results" :key="result._id">
                            <td>
                                {{ result.title }}
                            </td>
                            <td>
                                <button @click="$router.push(`/result/${result.id}/${number}`)" class="btn btn-sm btn-primary">
                                    <i class="fa fa-eye"></i>
                                    عرض الشهادة
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            loading: false,
            results: [],
            number: "",
            school_name: null,
            name: null
        }
    },
    created(){
        var g = this;
        $.post(api + '/user/results/info', {
            id: window.location.href.split("-link/")[1]
        }).then(function(r){
            g.loading = false;
            if(r.status != 100){
                alert(r.response, 200)
            }else{
                g.school_name = r.response
            }
        }).fail(function(){
            alert("حدث خطأ", 200)
        })
    },
    methods: {
        getResults(){
            var g = this;
            g.loading = true;
            $.post(api + '/user/results/search', {
                number: g.number,
                id: window.location.href.split("-link/")[1]
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.results = r.response
                    g.name = null
                    if(g.results.length){
                        g.name = g.results[0].student.name
                    }
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث خطأ", 200)
            })
        }
    }
}
</script>

<style>

</style>